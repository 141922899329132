import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import SelectDeck from './components/SelectDeck';
import ChooseTitle from './components/ChooseTitle';
import InputCode from './components/InputCode';
import ShowImage from './components/ShowImage';
import Winner from './components/Winner';
import GameProgress from './components/GameProgress';
import './global.css';

function App() {
  return (
    <Router>
      <div className="container">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/select-deck" element={<SelectDeck />} />
          <Route path="/choose-title" element={<ChooseTitle />} />
          <Route path="/input-code/:player" element={<InputCode />} />
          <Route path="/show-image/:player" element={<ShowImage />} />
          <Route path="/winner" element={<Winner />} />
          <Route path="/game-progress" element={<GameProgress />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;