import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Home.css';  // We'll keep this for logo-specific styles

const Home = () => {
  const navigate = useNavigate();

  const handleNewGame = () => {
    localStorage.removeItem('gameData');
    navigate('/select-deck');
  };

  return (
    <div className="container">
      <img src="/Logo.svg" alt="Logo" className="logo" />
      <div className="button-container">
        <button onClick={handleNewGame} className="btn-success">New Game</button>
      </div>
    </div>
  );
};

export default Home;