import React, { useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import './ShowImage.css';

const ShowImage = () => {
  const { player } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { prompt, imageUrl, gameTitle, selectedDeck, players } = location.state || {};

  useEffect(() => {
    // Update game data in localStorage
    const gameData = JSON.parse(localStorage.getItem('gameData')) || {};
    gameData.gameTitle = gameTitle;
    gameData.selectedDeck = selectedDeck;
    gameData.players = { ...gameData.players, [player]: { prompt, imageUrl } };
    localStorage.setItem('gameData', JSON.stringify(gameData));
  }, [player, prompt, imageUrl, gameTitle, selectedDeck]);

  const handleNext = () => {
    const nextPlayer = parseInt(player) + 1;
    const updatedPlayers = {
      ...players,
      [player]: { prompt, imageUrl }
    };
    if (nextPlayer <= 6) {
      navigate(`/input-code/${nextPlayer}`, { 
        state: { 
          gameTitle, 
          selectedDeck,
          players: updatedPlayers
        }
      });
    } else {
      handleChooseWinner(updatedPlayers);
    }
  };

  const handleChooseWinner = (updatedPlayers) => {
    console.log("Data being passed to Winner:", { gameTitle, selectedDeck, players: updatedPlayers });
    navigate('/winner', { 
      state: { 
        gameTitle,
        selectedDeck,
        players: updatedPlayers
      }
    });
  };

  const handleShowImages = () => {
    window.open('/game-progress', '_blank');
  };

  return (
    <div className="container show-image-container">
      <h1>Player {player}</h1>
      <div className="image-wrapper">
        <img src={imageUrl} alt={`Player ${player}'s creation`} className="player-image" />
      </div>
      <p className="prompt-text">{prompt}</p>
      <div className="button-container">
        {parseInt(player) < 6 && (
          <button onClick={handleNext} className="btn-primary">
            Next Player
          </button>
        )}
        {parseInt(player) >= 2 && (
          <button onClick={() => handleChooseWinner({...players, [player]: { prompt, imageUrl }})} className="btn-success">
            Choose Winner
          </button>
        )}
        <button onClick={handleShowImages} className="btn-primary">
          Show Images
        </button>
      </div>
    </div>
  );
};

export default ShowImage;