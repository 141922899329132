import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Winner.css';

const Winner = () => {
  const [winnerData, setWinnerData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { gameTitle, players } = location.state || {};

  const chooseWinner = useCallback(async () => {
    if (!players || Object.keys(players).length === 0) {
      setError('No player data available.');
      setIsLoading(false);
      return;
    }

    try {
      let evaluationPrompt = `The game title is: ${gameTitle}\n\nPlayers' image prompts:\n`;
      Object.entries(players).forEach(([player, data]) => {
        evaluationPrompt += `Player ${player}: ${data.prompt}\n`;
      });
      
      evaluationPrompt += `\nChoose the best image prompt to represent the Game Title based on:
• Coherence to the Game title (1-7 points)
• Coherence between the elements of the prompt (1-3 points)

Please respond ONLY with:
Winner: [Player Number]
Explanation: [Provide specific reasons for the choice. Focus on how well the image prompt represents the game's theme, setting, or key elements, and how the elements of the prompt work together to create a coherent visual representation.]`;

      const response = await axios.post('https://api.openai.com/v1/chat/completions', {
        model: "gpt-3.5-turbo",
        messages: [
          {"role": "system", "content": "You are a judge for an image prompt game. Be concise in your responses."},
          {"role": "user", "content": evaluationPrompt}
        ]
      }, {
        headers: {
         'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          'Content-Type': 'application/json'
        }
      });

      const result = response.data.choices[0].message.content;
      
      const winnerLine = result.split('\n').find(line => line.startsWith('Winner:'));
      const explanationLine = result.split('\n').find(line => line.startsWith('Explanation:'));

      if (winnerLine && explanationLine) {
        const winnerPlayer = winnerLine.split(':')[1].trim().replace('Player ', '');
        const winnerPlayerData = players[winnerPlayer];
        if (winnerPlayerData) {
          setWinnerData({
            player: winnerPlayer,
            imageUrl: winnerPlayerData.imageUrl,
            explanation: explanationLine.split(':')[1].trim()
          });
        } else {
          throw new Error(`Winner player data not found for player ${winnerPlayer}.`);
        }
      } else {
        throw new Error("Unexpected response format from GPT");
      }
    } catch (error) {
      console.error('Error choosing winner:', error);
      setError(`An error occurred while choosing the winner: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  }, [gameTitle, players]);

  useEffect(() => {
    chooseWinner();
  }, [chooseWinner]);

  const handleNewGame = () => {
    navigate('/');
  };

    if (isLoading) return (
      <div className="loading-container">
        <img src="/hourglass.png" alt="Loading" className="loading-icon" style={{ marginTop: '200px' }}/>
        <p className="loading-text">Choosing winner...</p>
      </div>
    );

  if (error) {
    return (
      <div className="container winner-container">
        <h1>{gameTitle}</h1>
        <p className="error-message">{error}</p>
        <div className="button-container">
          <button onClick={handleNewGame} className="btn-success">New Game</button>
        </div>
      </div>
    );
  }

  return (
    <div className="container winner-container">
      <h1>{gameTitle}</h1>
      {winnerData && (
        <>
          <h2 className="winner-announcement">THE WINNER IS PLAYER {winnerData.player}</h2>
          <div className="image-container">
            <img 
              src={winnerData.imageUrl} 
              alt={`Winning creation for ${gameTitle}`} 
              className="winning-image" 
            />
          </div>
          <p className="explanation">{winnerData.explanation}</p>
        </>
      )}
      <div className="button-container">
        <button onClick={handleNewGame} className="btn-success">New Game</button>
      </div>
    </div>
  );
};

export default Winner;