import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import './InputCode.css';

const InputCode = () => {
  const [codes, setCodes] = useState(['', '', '', '', '', '']);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [deckData, setDeckData] = useState(null);
  const { player } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { gameTitle, selectedDeck, players: existingPlayers } = location.state || {};

  const appId = process.env.REACT_APP_APP_ID;
  const apiKey = process.env.REACT_APP_APP_KEY;
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    document.title = `Player ${player} - Input Code`;
    console.log('Environment Variables:', { appId, apiKey, apiUrl });
    const loadDeckData = async () => {
      try {
        const deckName = selectedDeck.charAt(0).toUpperCase() + selectedDeck.slice(1);
        const data = await import(`../${deckName}Data.js`);
        setDeckData(data);
      } catch (error) {
        console.error('Error loading deck data:', error);
        setErrorMessage('Error loading deck data. Please try again.');
      }
    };
    loadDeckData();
  }, [player, selectedDeck, appId, apiKey, apiUrl]);

  const handleInputChange = (index, value) => {
    const newCodes = [...codes];
    newCodes[index] = value.replace(/\D/g, '').slice(0, 3);
    setCodes(newCodes);
    setErrorMessage('');
    
    if (value.length === 3 && index < 5) {
      document.getElementById(`code-${index + 1}`).focus();
    }
  };

  const generatePrompt = () => {
    if (!deckData) return '';
    const validTexts = codes
      .map(code => deckData.CODE_TO_TEXT[code] || '')
      .filter(text => text !== '');
    
    return validTexts.join(' ');
  };

  const handleCreateImage = async () => {
    const prompt = generatePrompt();
    if (prompt === '') {
      setErrorMessage('Please enter at least one valid code to create an image.');
      return;
    }

    setIsLoading(true);
    setErrorMessage('');

    try {
      console.log('Sending request to Dify API...');
      const response = await fetch(`${apiUrl}/chat-messages`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${apiKey}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          app_id: appId,
          inputs: {},
          query: prompt,
          response_mode: "blocking",
          user: `player-${player}-${Date.now()}`
        })
      });

      if (!response.ok) {
        const errorBody = await response.text();
        console.error('API response not OK:', response.status, errorBody);
        throw new Error(`HTTP error! status: ${response.status}, body: ${errorBody}`);
      }

      const data = await response.json();
      console.log('API Response:', data);

      let imageUrl = '';
      if (data.answer) {
        const match = data.answer.match(/!\[image\]\((.*?)\)/);
        if (match && match[1]) {
          imageUrl = match[1];
        }
      }

      if (imageUrl) {
        console.log('Image URL found:', imageUrl);
        const updatedPlayers = {
          ...existingPlayers,
          [player]: { prompt, imageUrl }
        };

        const gameData = { gameTitle, selectedDeck, players: updatedPlayers };
        localStorage.setItem('gameData', JSON.stringify(gameData));
        
        navigate(`/show-image/${player}`, { 
          state: { 
            prompt,
            imageUrl,
            gameTitle,
            selectedDeck,
            players: updatedPlayers
          }
        });
      } else {
        console.error('No image URL found in the response');
        throw new Error('No image URL found in the response');
      }

    } catch (error) {
      console.error('Error creating image:', error);
      setErrorMessage(`An error occurred: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const isAnyValidCodeEntered = deckData && codes.some(code => deckData.CODE_TO_TEXT[code]);

  return (
    <div className="container">
      <h1>Player {player}</h1>
      <div className="code-inputs">
        {codes.map((code, index) => (
          <input
            key={index}
            id={`code-${index}`}
            type="tel"
            inputMode="numeric"
            pattern="[0-9]*"
            maxLength="3"
            value={code}
            onChange={(e) => handleInputChange(index, e.target.value)}
          />
        ))}
      </div>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <div className="button-container">
        <button 
          onClick={handleCreateImage} 
          disabled={isLoading || !isAnyValidCodeEntered} 
          className="btn-success"
        >
          Create Image
        </button>
      </div>
      {isLoading && (
        <div className="loading-container">
          <img src="/hourglass.png" alt="Loading" className="loading-icon" />
          <p className="loading-text">Rendering your prompt...</p>
        </div>
      )}
    </div>
  );
};

export default InputCode;