import React from 'react';
import { useNavigate } from 'react-router-dom';
import './SelectDeck.css';

const SelectDeck = () => {
  const navigate = useNavigate();

  const decks = [
    { id: 'history', name: 'History' },
    { id: 'today', name: 'Today' },
    { id: 'fantasy', name: 'Fantasy' },
    { id: 'scifi', name: 'SciFi' },
    { id: 'villains', name: 'Villains' }
  ];

  const handleDeckSelect = (deckId) => {
    navigate('/choose-title', { state: { selectedDeck: deckId } });
  };

  return (
    <div className="container">
      <h1>Select Deck</h1>
      <div className="button-container deck-list">
        {decks.map(deck => (
          <button 
            key={deck.id} 
            onClick={() => handleDeckSelect(deck.id)}
            className="btn-primary"
          >
            {deck.name}
          </button>
        ))}
      </div>
    </div>
  );
};

export default SelectDeck;