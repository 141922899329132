import React, { useState, useEffect } from 'react';
import './GameProgress.css';

const GameProgress = () => {
  const [gameData, setGameData] = useState(null);

  useEffect(() => {
    const fetchGameData = () => {
      try {
        const data = JSON.parse(localStorage.getItem('gameData'));
        setGameData(data);
      } catch (error) {
        console.error('Error fetching game data:', error);
      }
    };

    fetchGameData();
    const interval = setInterval(fetchGameData, 5000);

    return () => clearInterval(interval);
  }, []);

  if (!gameData) {
    return <div className="loading">Loading game data...</div>;
  }

  return (
    <div className="game-progress">
      <h1 className="game-title">{gameData.gameTitle}</h1>
      <div className="players-grid">
        {[1, 2, 3, 4, 5, 6].map((playerNumber) => {
          const playerData = gameData.players[playerNumber];
          return (
            <div key={playerNumber} className="player-card">
              <h2 className="player-name">Player {playerNumber}</h2>
              {playerData && playerData.imageUrl ? (
                <img src={playerData.imageUrl} alt={`Player ${playerNumber}'s creation`} className="player-image" />
              ) : (
                <div className="placeholder-image">Waiting for image...</div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GameProgress;