import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './ChooseTitle.css';

const ChooseTitle = () => {
  const [title, setTitle] = useState('');
  const [titles, setTitles] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const selectedDeck = location.state?.selectedDeck || 'history';

  useEffect(() => {
    const loadDeckData = async () => {
      try {
        const deckName = selectedDeck.charAt(0).toUpperCase() + selectedDeck.slice(1);
        const data = await import(`../${deckName}Data.js`);
        setTitles(data.TITLES);
        changeTitle(data.TITLES);
      } catch (error) {
        console.error('Error loading deck data:', error);
      }
    };

    loadDeckData();
  }, [selectedDeck]);

  const changeTitle = (availableTitles) => {
    const randomIndex = Math.floor(Math.random() * availableTitles.length);
    setTitle(availableTitles[randomIndex]);
  };

  const handleStartGame = () => {
    navigate('/input-code/1', { state: { gameTitle: title, selectedDeck } });
  };

  return (
    <div className="container">
      <h1>Choose a Title</h1>
      <div className="title-display">{title}</div>
      <div className="button-container">
        <button onClick={() => changeTitle(titles)} className="btn-primary">Change Title</button>
        <button onClick={handleStartGame} className="btn-success">Start Game</button>
      </div>
    </div>
  );
};

export default ChooseTitle;